<template>
  <div id="organizationDetails" style="padding-top: 126px">
    <app-header />
    <!-- 位置 -->
    <div class="current_position w">
      <div class="current_position_text">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, i) in $route.meta.breadcrumb"
          :to="item.path ? { path: item.path } : ''"
          :key="i"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <!-- 服务机构 -->
    <div class="institution_list w">
      <div class="img">
        <img src="../../assets/image/组织.png" alt="" />
      </div>
      服务机构
    </div>
    <!-- 内容 -->
    <div class="site_box w">
      <div
        class="site"
        v-for="item in result"
        :key="item.id"
        @click="$router.push(`/particulars/${item.id}`)"
      >
        <div class="logo">
          <img class="pic" :src="item.agency_img" alt="" />
        </div>
        <div class="message">
          <div class="th">{{ item.agency_name }}</div>
          <div class="tb">所属区域：{{ item.address }}</div>
          <div class="tb">电话:{{ item.mobile }}</div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="block w">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="page_sizes"
        :page-size="+page.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.count"
      >
      </el-pagination>
    </div>
    <!--  -->
    <app-footer />
  </div>
</template>

<script>
import Header from '../../components/Header/header.vue';
import { service_agency_list } from '../../api/policies.js';
import Footer from '../../components/Footer/footer.vue';
export default {
  data() {
    return {
      page_sizes: [10, 20, 30, 40],
      result: [],
      page: {
        page: '1',
        limit: '10',
      },
    };
  },
  created() {
    this.service_agency_list();
  },
  methods: {
    async service_agency_list(page = '1', limit = '10') {
      const parameter = {
        page,
        limit,
        keyword: '',
      };
      const { result, count } = await service_agency_list(parameter);
      this.page = { ...this.page, count };

      console.log(this.page);
      this.result = result;
      console.log('this.result', this.result);
    },
    async handleSizeChange(limit) {
      this.page.limit = limit;
      await this.service_agency_list('1', limit);
      console.log(limit);
    },
    async handleCurrentChange(current) {
      this.page.page = current;
      await this.service_agency_list(current, this.page.limit);
    },
  },
  components: { 'app-header': Header, 'app-footer': Footer },
};
</script>

<style scoped>
#organizationDetails .block {
  display: flex;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 130px;
  justify-content: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 	overflow: hidden;
 */
}
.w {
  width: 1520px;
  margin: 0 auto;
}
.current_position {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.d8f {
  color: #0d8eff;
}
.institution_list {
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.institution_list .img {
  width: 18px;
  height: 18px;
  /* border: 1px dashed #666; */
  margin-right: 20px;
}
.institution_list span img {
  width: 100%;
  height: 100%;
}
.site_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.site {
  display: flex;
  width: 730px;
  height: 166px;
  /* background-color: #6cf; */
  padding: 20px 0 0;
  border-top: 1px solid #e8e8e8;
}
.site .logo {
  object-fit: cover;
  width: 302px;
  height: 106px;
  overflow: hidden;
  border: 1px solid #e8e8e8;
}
.site .logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.site .message {
  flex: 1;
  margin-left: 20px;
  /* display: flex; */
}
.site .message .th {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  height: 33.33%;
}
.site .message .tb {
  height: 33.33%;
  font-size: 16px;
  color: #595959;
}
</style>
